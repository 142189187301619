html {
   position: relative;
   min-height: 100%;
}

div {
   white-space: pre-wrap;
   font-family: 'Manrope', sans-serif;
}

div.invisible {
   display: none;
}

label {
   float: left;
}

#checkbox1, #checkbox2 {
   padding-block: 1px;
}

span {
   display: block;
   overflow: hidden;
   padding: 0px 4px 0px 6px;
}

input.textInput {
   width: 25%;
}

.heading {
   left: 0;
   width: 100%;
   background-color: #9ed1fa;
   color: #FFFFFF;
   font-size: 30px;
   padding: 4px 4px 4px 8px;
}

.footer {
   position: absolute;
   overflow-y: auto;
   left: 0;
   bottom: 0px;
   width: 100%;
   background-color: #9ed1fa;
   color: #FFFFFF;
   font-size: 30px;
   padding: 4px 4px 4px 8px;
}

.column {
   flex: 50%;
}

.centered {
   margin: 0 25%;
}

.true-centered {
   margin: 0 auto;
   text-align: center;
}

.align-left {
   text-align: left;
   display: inline-block;
}

button {
   border-radius: 8px;
}

[id^=space] {
   white-space: nowrap;
}

[id^=body], #errorMessages, #currentTasks {
   padding: 4px 4px 4px 8px;
   background-color: #cee6fd;
}

[id^=inner] {
   display: inline-block;
}